import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import smalandsvillan from 'images/customers/smalandsvillan.svg'
import bosch from 'images/customers/bosch.svg'
import { Clerk } from '@formclerk/react'

import Layout from 'components/Layout'

export default ({ data }) => (
  <Layout pageTitle='Reklamprodukter'>
    <oma-grid-row>
      <section>
        <h1>Reklamprodukter</h1>
        <p>
          Vi levererar det mesta inom reklambranchen. Här kan du se några
          exempelprodukter. Kontakt oss gärna om du har några frågor! Samtliga
          priser förutsätter tryckfärdiga orginal och är ex. moms.
        </p>
      </section>
      <section className='products'>
        <div className='product'>
          <h2>Pennor</h2>
          <Img
            fluid={data.pencils.childImageSharp.fluid}
            className='product__image'
          />
          <p>6,95:-/st för minst 500 st, med lasergravyr.</p>
        </div>
        <div className='product'>
          <h2>Rollups</h2>
          <Img
            fluid={data.rollup.childImageSharp.fluid}
            className='product__image'
          />
          <p>695:-/st om man köper tre eller fler. 85x200 cm.</p>
        </div>
        <div className='product'>
          <h2>Banderoller</h2>
          <p>
            150:-/kvadratmeter. Upp till 5-metersbredder. Öljetterade och klara.
          </p>
        </div>
      </section>
      <section>
        <Clerk
          clerkID='73335081-54a3-4d4c-8ba0-7d06edb58f1d'
          Processing={() => <p>Skickar informationen...</p>}
          Success={() => (
            <p>Tack för ditt intresse, vi hör av oss så snart vi kan!</p>
          )}
          Failure={() => (
            <p>
              Hmm... Något verkar ha blivit fel, du får gärna kontakta oss
              direkt via telefon eller e-post istället.
            </p>
          )}
        >
          <label>
            Företagsnamn:
            <input type='text' name='företagsnamn' />
          </label>
          <label>
            Kontaktperson:
            <input type='text' name='kontaktperson' />
          </label>
          <label>
            E-post:
            <input type='email' name='e-post' />
          </label>
          <label>
            Telefon:
            <input type='text' name='telefon' />
          </label>
          <label>
            Beskriv ditt ärende:
            <textarea rows='4' name='ärende' />
          </label>
          <input type='submit' value='Kontakta mig' />
        </Clerk>
      </section>
      <section>
        <h2>Kunder</h2>
        <p>
          Här är ett axplock av några kunder som vi har jobbat med under åren.
        </p>
        <div className='customers'>
          <Img
            fixed={data.mekonomen.childImageSharp.fixed}
            className='customer__logotype'
            alt='Mekonomen logotyp'
          />
          <img
            src={smalandsvillan}
            alt='Smålandsvillan logotyp'
            className='customer__logotype'
          />
          <Img
            fixed={data.gefleortens.childImageSharp.fixed}
            className='customer__logotype'
            alt='Gefleortens Mejeriföretag logotyp'
          />
          <img src={bosch} alt='Bosch logotyp' className='customer__logotype' />
          <Img
            fixed={data.mellanskog.childImageSharp.fixed}
            className='customer__logotype'
            alt='Mellanskog logotyp'
          />
          <Img
            fixed={data.storaenso.childImageSharp.fixed}
            className='customer__logotype'
            alt='Stora Enso logotyp'
          />
          <Img
            fixed={data.avis.childImageSharp.fixed}
            className='customer__logotype'
            alt='Avis biluthyrning logotyp'
          />
        </div>
      </section>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  query {
    pencils: file(relativePath: { eq: "advertising/pencils.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rollup: file(relativePath: { eq: "advertising/rollup-bosch.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mekonomen: file(relativePath: { eq: "customers/mekonomen.jpg" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    gefleortens: file(relativePath: { eq: "customers/gefleortens.png" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mellanskog: file(relativePath: { eq: "customers/mellanskog.jpg" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    storaenso: file(relativePath: { eq: "customers/storaenso.png" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    avis: file(relativePath: { eq: "customers/avis.png" }) {
      childImageSharp {
        fixed(width: 200, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
